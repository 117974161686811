@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100;
  }
}

@import './theme/module.scss';

.table-overflow-x-auto {
  overflow-x: auto !important;
  width: 87vw;
}

.bg-red-500 {
  background-color: $color-danger;
  color: $color-white;
}

.bg-blue-500 {
  background-color: $color-primary;
  color: $color-white;
}

// .bg-green-200 {
//   background-color: $color-success;
//   color: $color-white;
// }

// .bg-blue-200 {
//   background-color: $color-info;
//   color: $color-white;
// }

.bg-red-200 {
  background-color: $color-danger;
  color: $color-white;
}

.react-joyride__floater {
  z-index: 9999 !important;  /* Ensures it appears above other content */
}
