@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        margin: 10px;
    }
}

.account {
    margin: 60px 120px;


    &__intro {
        display: flex;
        flex-direction: column;

        &__title {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        &__description {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__profile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;

        &__photo {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-bottom: 20px;
        }

        &__email {
            font-size: 16px;
            font-weight: 600;
        }
    }

    @include mobile();
}