@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {}
}


.compose-message {

    &__title {
        font-size: 18px;
        font-weight: 600;
    }

    &__subtitle {
        margin-top: 17px;
        font-size: 16px;
        font-weight: 600;
    }

    &__contacts {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 12px;

        &__item {

            &__label {
                font-size: 12px;
                color: $color-gray;
            }

            &__link {
                font-size: 12px;
                font-weight: 600;
                color: $color-primary;
            }
        }
    }

    &__email-body {
        margin: 0px;
    }

    &__container-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        &__email-reset {
            margin-top: 5px;
        }
        
        &__email-send {
            margin-top: 5px;
    
            & button {
                width: 120px;
            }
        }
    }

    

    @include mobile();
}