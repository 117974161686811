@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {}
}


.add-contact-response{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 2rem;

    &__title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 1rem;
    }

    &__subtitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 1rem;
        color: $color-gray;
        text-align: center;
    }

    &__description {
        padding-top: 1.5rem;
        width: 100%;

        &__text {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 1rem;
            text-align: center;
        }
    }

    @include mobile();
}