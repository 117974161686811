@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {}
}


.subtitle {
    font-size: 16px;
    font-weight: 600;

    @include mobile();
}