@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        width: 100%;
    }
}


.avatars{
    display: flex;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    width: 112px;

    @include mobile();
}