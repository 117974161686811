@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-sm() {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        &__info {
            flex-direction: column;
            align-items: center;

            &__text {
                margin-top: 1rem;

                &__name {
                    text-align: center;
                }

                &__email {
                    text-align: center;
                }
            }

            &__action {
                display: flex;
                justify-content: center;
                align-items: center;

                &__settings {
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;
                }
            }
        }
    }
}


.contact-card {
    min-height: 142px;
    padding: 16px;
    border-radius: 9px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.212);
    background-color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .bg-green {
            background-color: $color-success;
        }

        .bg-purple {
            background-color: $color-primary;
        }

        &__circle-profile {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $color-success;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            color: $color-white;
            font-weight: 600;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }

        &__text {
            margin-top: 1rem;

            &__name {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: .5rem;
            }

            &__email {
                font-size: 14px;
                color: $color-gray;
            }
        }

        &__action {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-end;

            &__settings {
                margin-bottom: 1.5rem;
            }
        }
    }

    @include mobile();
}