@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        &__label {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: .5rem;
        }
    
        &__search {
            grid-template-columns: 1fr;
            row-gap: .5rem;
    
            &__input {
                margin-left: 0px;
            }

            &__avatars {
                display: flex;
                gap: .5rem;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
    }
}

.select-contacts {

    &__label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    &__search {
        display: grid;
        column-gap: 1rem;
        grid-template-columns: 1fr auto 1fr;

        &__input {
            height: 42px;
            border-radius: 9px;
            padding: 0 1rem;
            border: 1px solid $color-ligth-gray;
            background-color: $color-white;
            font-size: 14px;
            font-weight: 400;
            color: $color-gray;
            display: flex;
            align-items: center;
            margin-left: 1rem;
            margin-right: 1rem;
            position: relative;

            &__remove {
                cursor: pointer;
                background-color: $color-danger;
                border: 1px solid $color-danger;
                color: $color-white;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 8px;
                position: absolute;
                right: -10px;
                top: -10px;
                
                &:hover {
                    background-color: $color-white;
                }
                
                &__icon {
                    color: $color-white;

                    &:hover {
                        color: $color-danger;
                    }
                }
                
            }
        }

        &__avatars {
            display: flex;
            gap: .5rem;
            justify-content: center;
            align-items: center;
            width: 112px;
        }
    }

    @include mobile();
}