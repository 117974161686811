@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {}
}


.intro-stats {
    padding: 20px;
    background-color: $color-white;
    border-radius: 9px;
    box-shadow: 0px 2px 2px rgba(177, 177, 177, 0.1);


    &__title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    &__list {
        display: grid;
        // grid-template-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            background-color: $color-white;
            border-radius: 9px;

            &__counter {
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 10px;
                text-align: center;
                
                &.red {
                    color: $color-danger;
                }
                
                &.purple {
                    color: $color-purple;
                }
                
                &.green {
                    color: $color-success;
                }
            }
            
            &__description {
                font-size: 12px;
                font-weight: 600;
                color: $color-gray;
                text-align: center;
            }
        }
    }



    @include mobile();
}