@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        font-size: 14px;
        height: 41px;
        box-shadow: 0px 4px 4px rgba(70, 70, 70, 0.1);
        display: flex;

        &__logo {
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
}


.google {
    height: 54px;
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    border-radius: 9px;
    padding: 0 60px;
    background-color: $color-white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);

    &__logo {
        width: 30px;
        height: 30px;
        margin-right: 12px;
    }

    @include mobile();
}