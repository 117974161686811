// X-Small devices (portrait phones, less than 576px)
@mixin breakpoint-mobile-xs() {
    @media (max-width: 991.98px) {
      @content;
    }
  }
  
  @mixin breakpoint-exclude-mobile-xs() {
    @media (min-width: 992px) {
      @content;
    }
  }
  
  // Small devices (landscape phones, less than 768px)
  @mixin breakpoint-mobile-sm() {
    @media (max-width: 767.98px) {
      @content;
    }
  }
  
  // Medium devices (tablets, less than 992px)
  @mixin breakpoint-tablet-md() {
    @media (min-width:767.99px) and (max-width: 991.98px) {
      @content;
    }
  }
  
  @mixin breakpoint-tablet-md-1 () {
    @media (max-width: 1100px) {
      @content;
    }
  }
  
  
  @mixin breakpoint-tablet-md-2 () {
    @media (max-width: 1000px) {
      @content;
    }
  }
  
  // Large devices (desktops, less than 1200px)
  @mixin breakpoint-desktop-lg() {
    @media (min-width: 991.99px) and (max-width: 1199.98px) {
      @content;
    }
  }
  
  // X-Large devices (large desktops, 1200px and up)
  @mixin breakpoint-desktop-xl() {
    @media (min-width: 1200px) {
      @content;
    }
  }
  
  // XX-Large devices (larger desktops, 1400px and up)
  @mixin breakpoint-desktop-xxl() {
    @media (min-width: 1400px) {
      @content;
    }
  }
  @mixin breakpoint-table-responsive() {
    @media (max-width: 1400px) {
      @content;
    }
  }
  
  // Custom
  @mixin breakpoint-mobile-tablet() {
    @media(max-width: 991.98px){
      @content;
    }
  }
  @mixin breakpoint-desktop() {
    @media(min-width: 991.99px){
      @content;
    }
  }
  
  @mixin breakpoint-no-mobile() {
    @media(min-width: 767.99px){
      @content;
    }
  }
  
  @mixin mobile-tablet-laptop() {
    @media(max-width: 1200.99px){
      @content;
    }
  }
  