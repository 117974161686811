@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-table-responsive() {
        width: 91vw;
    }
}

.responsive-table {

    table {
        width: 100%;
    }

    @include mobile();
}