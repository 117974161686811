@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {

        &__content {
    
            &__title {
                font-size: 30px;
            }
    
            &__description {
                font-size: 18px;
            }

            &__container-btn {
                width: 100%;
                margin-top: 3rem;
                display: flex;
                justify-content: center;
            }
    
        }
    }
}

.introduction {
    &__banner {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__content {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        &__title {
            font-size: 38px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        &__description {
            font-size: 25px;
            margin-top: 20px;
            font-weight: 600;
        }

        &__logo {
            margin-top: 50px;
            width: 117px;
        }

        &__container-btn {
            margin-top: 1rem;
            width: 80%;
            display: flex;
            justify-content: end;

            button {
                width: 200px;
            }
        }

    }

    @include mobile();
}