@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        &__form {
            display: grid;
            gap: 1rem;
            grid-template-areas: "one"
                                    "two"
                                    "three"
                                    "four"
                                    "five"
                                    "six";
    
        }
    }
}


.new-contact {
    margin: .5rem .5rem;
    box-shadow: 0px 0px 7px 0px rgba(67, 67, 67, 0.1);
    border-radius: 9px;
    padding: 1px .5rem .5rem .5rem;
    background-color: $color-white;
    margin: 10px;

    &__title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    &__container {
        display: flex;
        width: 100%;
        margin-bottom: .5rem;

        &__img{
            width: 80px;
            height: 80px;
        }
    }

    &__form {
        display: grid;
        gap: 1rem;
        grid-template-areas: "one two"
                             "three four"
                             "five five"
                             "six six";

        .one {
            grid-area: one;
        }

        .two {
            grid-area: two;
        }

        .three {
            grid-area: three;
        }

        .four {
            grid-area: four;
        }

        .five {
            grid-area: five;
        }

        .six {
            grid-area: six;
        }

    }

    @include mobile();
}