@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {}
}

@mixin btn() {
    width: 100%;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 9px;
}


.button {

    &__primary {
        @include btn();
        background-color: $color-primary;
        color: $color-white;

        &:hover {
            cursor: pointer;
            background-color: darken($color: #1A56DB, $amount: 10)
        }
    }

    &__warning {
        @include btn();
        background-color: $color-warning;
        color: $color-white;

        &:hover {
            cursor: pointer;
            background-color: darken($color: #1A56DB, $amount: 10)
        }
    }

    &__danger {
        @include btn();
        background-color: $color-danger;
        color: $color-white;

        &:hover {
            cursor: pointer;
            background-color: darken($color: #1A56DB, $amount: 10)
        }
    }

    &:disabled, &.disabled {
        background-color: $color-gray;
        color: $color-white;
        cursor: not-allowed;
    }

    @include mobile();
}