@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        width: 91vw;
    }
}


.custom-mobile-modal {
    
    @include mobile();
}