@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        &__navbar { 
            display: flex;
            justify-content: start;
            align-items: center;
            height: 50px;
            margin: 15px;
        }

        &__content {
            display: flex;
            justify-content: center;
    
            &__card {
                display: grid;
                grid-template-columns: 1fr;
                background-color: $color-white;
                margin: 15px;
                padding: 20px;
                box-shadow: 0px 4px 4px rgba(122, 121, 121, 0.1);
    
                &__image {
                    display: none;
                }
    
                &__information {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    &__container {
    
                        &__title {
                            font-size: 20px;
                            font-weight: bold;
                            margin-bottom: 20px;
                        }
    
                        &__subtitle {
                            font-size: 16px;
                            margin-bottom: 20px;
                            font-weight: 600;
                        }
    
                        &__list {
                            display: flex;
                            flex-direction: column;
    
                            &__item {
                                display: flex;
                                align-items: center;
                                margin-bottom: 10px;
                                font-size: 16px;
                                font-weight: 500;
                                margin-top: 10px;
    
                                img {
                                    margin-right: 12px;
                                }
                            }
                        }
    
                        &__legal {
                            font-size: 14px;
                            margin-top: 20px;
                        }
                    }
    
                }
            }
        }

        &__footer {
           margin: 15px;
           font-size: 16px;
           display: block;
        }
    }
}

.login {
    &__navbar {
        background-color: $color-bg;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;

        &__logo {
            width: 100px;
        }
    }

    &__content {
        display: flex;
        justify-content: center;

        &__card {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
            background-color: $color-white;
            padding-right: 40px;

            &__image {
                width: 100%;
                border-radius: 10px;
            }

            &__information {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &__container {

                    &__title {
                        font-size: 30px;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }

                    &__subtitle {
                        font-size: 16px;
                        margin-bottom: 20px;
                        font-weight: 600;
                    }

                    &__list {
                        display: flex;
                        flex-direction: column;

                        &__item {
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 10px;

                            img {
                                margin-right: 12px;
                            }
                        }
                    }

                    &__legal {
                        font-size: 14px;
                        margin-top: 20px;
                    }
                }

            }
        }
    }

    &__footer {
        margin: 15px;
        font-size: 16px;
        display: none;
     }

    @include mobile();
}