@import '../variables.scss';
@import '../components/breakpoints.component.scss';

@mixin desktop-lg {
    @include breakpoint-desktop-lg() {
        .home {
            margin: 10px;
        }

        .home-table {
            margin: 10px;
        }
    }
}

@mixin mobile {
    @include breakpoint-mobile-tablet() {
        .home {
            margin: 10px;
            grid-template-columns: 1fr;
        }

        .home-table {
            margin: 30px  10px;
        }
    }

}

.home {
    margin: 30px 100px 20px 100px;
    display: grid;
    grid-template-columns: 45% 55%;
    grid-gap: 1rem;

    &__intro {

        &__title {
            font-weight: bold;
            font-size: 30px;
        }

        &__description {
            font-weight: 600;
            font-size: 16px;
        }

        &__subtitle {
            margin-top: 12px;
            font-weight: 500;
            font-size: 16px;
            color: #6B7280;
        }

        &__buttons {
            margin-top: 20px;
            display: flex;
            justify-content: space-evenly;
            gap: 20px;
        }

    }

}

.home-table {
    margin: 50px 100px 20px 100px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    &__card {
        border-radius: 9px;
        background-color: $color-white;

        &__tabs {
            display: flex;
            margin: 20px 20px;
        }

        &__search {
            display: flex;
            justify-content: space-between;
            margin: 20px 20px;
            height: 57px;
            max-width: 450px;

            &__icon {
                color: #6B7280;
                height: 25px;
                position: relative;
                top: 15px;
                right: -40px;

            }

            &__input {
                width: 100%;
                border: none;
                border: 1px solid #b3b5b9;
                border-top-left-radius: 9px;
                border-bottom-left-radius: 9px;
                font-size: 14px;
                font-weight: 600;
                padding-left: 60px;
            }

            &__button {
                background-color: $color-primary;
                color: $color-white;
                border: none;
                border-top-right-radius: 9px;
                border-bottom-right-radius: 9px;
                padding: 0px 20px;
                font-size: 14px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background-color: darken($color-primary, 10%);
                }

                &:disabled {
                    background-color: $color-gray;
                    cursor: not-allowed;
                }
            }
        }
    }
}

@include mobile;

@include desktop-lg;