$color-primary: #1A56DB;
$color-white: white;

$color-bg: #f3f4f6;

$color-danger: #DB4B5C;
$color-purple: #5850EC;
$color-success: #0E9F6E;
$color-warning: #F59E0B;
$color-info: #2D9CDB;

$color-gray: #6B7280;
$color-ligth-gray: #D1D5DB;
