/* Basic editor styles */
.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul, 
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    
    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  p {
    color: black;
  }
  /* Heading styles */
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1, 
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 { 
    font-size: 1.4rem; 
  }

  h2 { 
    font-size: 1.2rem; 
  }

  h3 { 
    font-size: 1.1rem; 
  }

  h4, 
  h5, 
  h6 { 
    font-size: 1rem; 
  }

  /* Code and preformatted text styles */
  code {
    background-color: lightslategray;
    border-radius: 0.4rem;
    color: black;
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }

  pre {
    background: black;
    border-radius: 0.5rem;
    color: white;
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    code {
      background: none;
      color: inherit;
      font-size: 0.8rem;
      padding: 0;
    }
  }

  blockquote {
    border-left: 3px solid lightslategray;
    margin: 1.5rem 0;
    padding-left: 1rem;
  }

  hr {
    border: none;
    border-top: 1px solid var(--gray-2);
    margin: 2rem 0;
  }

  /* Link styling */
  a {
    color: #1D4ED8; /* Tailwind blue */
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: #2563EB; /* Slightly darker blue on hover */
    }

    &:focus {
      outline: 2px solid #2563EB; /* Focus outline for accessibility */
      outline-offset: 2px;
    }
  }
}

/* Override ProseMirror Focus Style */
.ProseMirror-focused {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
